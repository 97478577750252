@keyframes moving-gradient {
  0% {
    background-position: -250px 0;
  }

  100% {
    background-position: 250px 0;
  }
}

@keyframes fadeIn {
  99% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}
